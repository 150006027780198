import React, { useEffect, useState, PureComponent } from 'react';
import { Select, Button, message, Typography } from 'antd';
import ProTable from '@ant-design/pro-table';
import axios from 'axios';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { tripTypeColor } from '../Helpers';
import LimitDateRangePicker from '../Components/LimitDateRangePicker';
const { DateTime } = require("luxon");
const dayjs = require('dayjs');

const { Text, Title } = Typography;
const { Option } = Select;

export default function Truck(props) {
    const [data, setData] = useState([]);

    const [truckTags, setTruckTags] = useState(["863500060464901", "868759037178565"]);  // For truck selection
    //const [truckDate, setTruckDate] = useState(dayjs());  // For date selection
    const [loading, setLoading] = useState(false);
    const [trucksMap, setTrucksMap] = useState({});
    const [truckDate, setTruckDate] = useState({ start: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss"), end: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss") });
    const [yDomain, setYDomain] = useState("auto");
    const [trucksTotal, setTrucksTotal] = useState([]);
    const [hoveredTruck, setHoveredTruck] = useState(null);
    const [activeHoursPerTruck, setActiveHours] = useState({});
    /*
    1 - single day trips per hour statistics
    2 - trips count per day
    */
    const [chartType, setChartType] = useState(null);

    useEffect(() => {
        if (truckDate && truckTags.length > 0) {
            fetchData();
        }
    }, [])

    useEffect(() => {
        if (props.trucks && props.trucks.length > 0) {
            let mapTemp = {};
            props.trucks.forEach(truck => {
                mapTemp[truck.tag] = truck.name;
            });
            setTrucksMap(mapTemp);
        }
    }, [props.trucks]);

    // Chart series and axes configuration
    const series = { type: 'bar' };
    const axes = [
        {
            primary: true,
            type: 'time',
            position: 'bottom',
            show: true,
            tickCount: data?.length || 24,  // Adjust for the number of hours
            format: (hour) => `${hour * 1}:00`
        },
        { type: 'linear', position: 'left' }
    ];

    function transformData(apiData, truckActiveHours = {}) {
        const result = {};
        let trucksTotalArray = [];
        console.log(activeHoursPerTruck);
        Object.keys(apiData).forEach((truck) => {
            let activeHours = -1;
            if(truckActiveHours && truckActiveHours[truck]) {
                activeHours = truckActiveHours[truck];
            }
            else if(truckActiveHours) {
                message.error("Failed to fetch truck active hours.");
            }
            const activeDaysOrHours = (activeHours != -1) ? activeHours : apiData[truck].length;
            const total = apiData[truck].reduce((sum, item) => sum + item.loadings + item.unloadings + item.yard_shifts, 0);
            const loadings = apiData[truck].reduce((sum, item) => sum + item.loadings, 0);
            const unloadings = apiData[truck].reduce((sum, item) => sum + item.unloadings, 0);
            const yard_shifts = apiData[truck].reduce((sum, item) => sum + item.yard_shifts, 0);

            trucksTotalArray.push({
                truck, total, loadings, unloadings, yard_shifts, total_average: (total / activeDaysOrHours).toFixed(2),
                loadings_average: (loadings / activeDaysOrHours).toFixed(2), unloadings_average: (unloadings / activeDaysOrHours).toFixed(2),
                yard_shifts_average: (yard_shifts / activeDaysOrHours).toFixed(2)
            });
            apiData[truck].forEach((record) => {
                const date = record.trip_date;
                if (!result[date]) {
                    result[date] = { trip_date: date };
                }

                ["loadings", "unloadings", "yard_shifts"].forEach((key) => {
                    // Initialize trips_count to 0 so that += can be used
                    if (!result[date][`trips_count-${truck}`]) result[date][`trips_count-${truck}`] = 0;
                    // Count total trips for each truck
                    result[date][`trips_count-${truck}`] += record[key];

                    result[date][`${key}-${truck}`] = record[key];
                });
            });
        });

        setTrucksTotal(trucksTotalArray);
        return Object.values(result);
    }

    function transformDataByHour(apiData) {
        // Initialize a result object for hours 0-23
        const result = Array.from({ length: 24 }, (_, hour) => ({ hour }));
        let trucksTotalArray = [];
        Object.keys(apiData).forEach((truck) => {
            const activeHours = apiData[truck].length;
            const total = apiData[truck].reduce((sum, item) => sum + item.loadings + item.unloadings + item.yard_shifts, 0);
            const loadings = apiData[truck].reduce((sum, item) => sum + item.loadings, 0);
            const unloadings = apiData[truck].reduce((sum, item) => sum + item.unloadings, 0);
            const yard_shifts = apiData[truck].reduce((sum, item) => sum + item.yard_shifts, 0);

            trucksTotalArray.push({
                truck, total, loadings, unloadings, yard_shifts, total_average: (total / activeHours).toFixed(2),
                loadings_average: (loadings / activeHours).toFixed(2), unloadings_average: (unloadings / activeHours).toFixed(2),
                yard_shifts_average: (yard_shifts / activeHours).toFixed(2)
            });
            apiData[truck].forEach((record) => {
                const hour = record.hour;
                const hourData = result[hour];

                // type of trips to include in statistics
                ["loadings", "unloadings", "yard_shifts"].forEach((key) => {
                    // Initialize trips_count to 0 so that += can be used
                    if (!hourData[`trips_count-${truck}`]) hourData[`trips_count-${truck}`] = 0;
                    // Count total trips for each truck
                    hourData[`trips_count-${truck}`] += (hourData[`${key}-${truck}`] || 0) + record[key];

                    hourData[`${key}-${truck}`] = (hourData[`${key}-${truck}`] || 0) + record[key];
                });
            });
        });
        setTrucksTotal(trucksTotalArray);
        return result;
    }

    // Fetch data based on selected truck and date
    const fetchData = async () => {
        if (!truckTags || !truckDate) {
            message.error("Please select both truck and date.");
            return;
        }

        let { start, end } = truckDate
        const start_dt = start != null ? DateTime.fromISO(start) : null;
        const end_dt = end != null ? DateTime.fromISO(end) : null;
        const start_str = start_dt != null ? start_dt.toFormat("yyyy-MM-dd") : null;
        const end_str = end_dt != null ? end_dt.toFormat("yyyy-MM-dd") : null;
        setLoading(true);

        try {
            const response = await axios.get(`${window.vtrack_config.REACT_APP_API_URL}/metrics/trips_per_tractor`, {
                params: {
                    truck_tags: truckTags,
                    start_date: start_str,
                    end_date: end_str
                }
            });

            let chartTypeTemp;
            if (start_dt && start_dt.hasSame(end_dt, "day")) {
                setChartType(1);
                chartTypeTemp = 1;
            } else {
                setChartType(2);
                chartTypeTemp = 2;
            }

            if (response.status === 200) {
                const resultData = response.data;
                // API returns active hours per truck only if chartType is 2 (stats per day)
                setActiveHours(resultData.active_hours);
                let chartData = [];
                let trucksTotalArray = [];
                let maxYDomain = 0;
                if (chartTypeTemp == 1) {
                    chartData = transformDataByHour(resultData.trucks);
                } else if (chartTypeTemp == 2) {

                    chartData = transformData(resultData.trucks, resultData.active_hours);
                }

                chartData = chartData.sort((a, b) => new Date(a.trip_date) - new Date(b.trip_date));
                setYDomain(maxYDomain);
                setData(chartData);
            } else {
                message.error("Failed to load trips data.");
            }
        } catch (error) {
            console.log(error);
            message.error("An error occurred while fetching the data.");
        }

        setLoading(false);
    };

    const columns = [
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            width: 150,
            render: (text, record) =>
                <>
                    <Text>{trucksMap[text]}</Text>
                </>

        },
        {
            title: (chartType == 1 ? "Total (average / hour)" : chartType == 2 ? "Total (average / hour)" : "Total (average)"),
            dataIndex: 'total',
            key: 'total',
            render: (text, record) =>
                <>
                    <Text>{text} ({record.total_average})</Text>
                </>
        },
        {
            title: (chartType == 1 ? "Loadings (average / hour)" : chartType == 2 ? "Loadings (average / hour)" : "Loadings (average)"),
            key: "loadings",
            dataIndex: "loadings",
            render: (text, record) =>
                <>
                    <Text>{text} ({record.loadings_average})</Text>
                </>
        },
        {
            title: (chartType == 1 ? "Unloadings (average / hour)" : chartType == 2 ? "Unloadings (average / hour)" : "Unloadings (average)"),
            key: "unloadings",
            dataIndex: "unloadings",
            render: (text, record) =>
                <>
                    <Text>{text} ({record.unloadings_average})</Text>
                </>
        },
        {
            title: (chartType == 1 ? "Yard Shifts (average / hour)" : chartType == 2 ? "Yard Shifts (average / hour)" : "Yard Shifts (average)"),
            key: "yard_shifts",
            dataIndex: "yard_shifts",
            render: (text, record) =>
                <>
                    <Text>{text} ({record.yard_shifts_average})</Text>
                </>
        },
        {
            title: (chartType == 1 ? "Vessel Shifts (average / hour)" : chartType == 2 ? "Vessel Shifts (average / hour)" : "Vessel Shifts (average)"),
            key: "vessel_shifts",
            dataIndex: "vessel_shifts",
            render: (text, record) =>
                <>
                    <Text>-</Text>
                </>
        }
    ]

    function handleDateChange(date) {
        let start = date[0] != null ? DateTime.fromHTTP(date[0].toString()) : null;
        let end = date[1] != null ? DateTime.fromHTTP(date[1].toString()) : null;

        start = start ? start.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        end = end ? end.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;

        setTruckDate(
            {
                start,
                end
            }
        )
    }

    function CustomTooltip({ active, payload, label, labelFormatter }) {
        if (active && payload && payload.length && hoveredTruck) {
            // Filter payload to show only data for the hovered truck
            const newPayload = payload.filter(item =>
                item.dataKey.includes(hoveredTruck)
            );

            newPayload.push({
                name: "Trips count",
                value: payload[0].payload[`trips_count-${hoveredTruck}`]
            }
            )

            if (newPayload.length === 0) return null;

            //return <DefaultTooltipContent {...props} payload={newPayload} />
            return (
                <div className="custom-tooltip">
                    <Text strong>{trucksMap[hoveredTruck]}</Text>

                    <span className="tooltip-label">{labelFormatter ? labelFormatter(label, payload) : label}</span>
                    {newPayload.map((entry, index) => (
                        <div className="tooltip-entry" key={`tooltip-entry-${index}`} style={{ color: entry.fill }}>

                            <strong>{entry.name}:</strong> {entry.value}


                        </div>
                    ))}
                </div>
            );
        } else {
            return null;
        }
    }

    function formatHour(hourNumber) {
        hourNumber = hourNumber.toString();
        while (hourNumber.length < 2) hourNumber = "0" + hourNumber;
        return hourNumber;
    }

    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const value = payload.value;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${formatHour(value)}:00`}
                    </text>
                </g>
            );
        }
    }

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const date = new Date(payload.value);
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${date.toLocaleDateString("en-UK", {
                            month: "short",
                            day: "numeric",
                        })} (${date.toLocaleDateString("en-UK", { weekday: "short" })})`}
                    </text>
                </g>
            );
        }
    }

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>

            <Title level={2}>Trips per Day</Title>

            {/* Truck selection */}
            <div style={{ display: 'flex', columnGap: 32 }}>
                <div>
                    <Text>Select Truck:</Text>
                    <Select
                        style={{ width: 200, marginLeft: 10 }}
                        placeholder="Select truck"
                        mode="multiple"
                        allowClear
                        value={truckTags}
                        onChange={(value) => setTruckTags(value)}
                        options={props.dataZoomTrucksLookup}
                    >
                    </Select>
                </div>
                {/* Date selection */}
                <div >
                    <Text>Select Date:</Text>

                    <LimitDateRangePicker handleDateChange={handleDateChange} />
                </div>
            </div>


            {/* Fetch data button */}
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={fetchData} loading={loading}>
                    Fetch Data
                </Button>
            </div>

            {/* Charts */}
            <div style={{ marginTop: 20 }}>

                <div>

                    <div style={{ height: '400px', marginTop: 16 }}>
                        {/* <Chart data={[truckData]} series={series} axes={axes} tooltip /> */}
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={200}
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 90,
                                }}
                                padding={{
                                    bottom: 128,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                {chartType == 1 && (
                                    <React.Fragment>
                                        <XAxis dataKey="hour"
                                            tick={<CustomizedXAxisTick />}
                                            height={80}
                                            label={{
                                                value: "Hour",
                                                position: 'insideBottomRight',
                                                dx: 38,
                                                offset: 10,
                                                dy: -40
                                            }}
                                        />
                                        <YAxis
                                            label={{
                                                value: 'Number of Trips',
                                                angle: -90, // Rotate the label for vertical orientation
                                                position: 'insideLeft', // Position the label inside the Y-axis on the left
                                                offset: -10, // Adjust offset to move it closer to the axis
                                                style: { textAnchor: 'middle' } // Center-align the text
                                            }}
                                            domain={[0, yDomain]} />
                                        <Tooltip
                                            content={<CustomTooltip />}
                                            labelFormatter={(value, payload) => {
                                                return `Hours ${formatHour(value)}:00 - ${formatHour(value + 1)}:00`
                                            }}

                                            isAnimationActive={false}
                                        />


                                    </React.Fragment>
                                )}

                                {chartType == 2 && (
                                    <React.Fragment>
                                        <XAxis
                                            dataKey="trip_date"
                                            height={60}
                                            tick={<CustomizedAxisTick />}
                                            interval={0}
                                        />
                                        <YAxis domain={[0, yDomain]} />
                                        <Tooltip labelFormatter={(label) => {
                                            const date = new Date(label);
                                            return date.toLocaleDateString("en-UK", {
                                                weekday: "long",  // Full weekday name
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            });
                                        }}
                                            formatter={(value, name, props) => {
                                                return [value, name];
                                            }}
                                            content={<CustomTooltip />}
                                            isAnimationActive={false}
                                        />

                                    </React.Fragment>
                                )}

                                <Legend wrapperStyle={{ marginBottom: "-48px" }}
                                    payload={[
                                        { value: "Loadings", type: "square", id: 2, color: tripTypeColor[2] },
                                        { value: "Unloadings", type: "square", id: 1, color: tripTypeColor[1] },
                                        { value: "Yard shifts", type: "square", id: 3, color: tripTypeColor[3] }
                                    ]} />

                                {truckTags.map(tag => (
                                    <React.Fragment key={tag}>
                                        <Bar dataKey={`unloadings-${tag}`} onMouseOver={() => setHoveredTruck(tag)} onMouseOut={() => setHoveredTruck(null)} name="Unloadings" stackId={tag} fill={tripTypeColor[1]} />
                                        <Bar dataKey={`loadings-${tag}`} onMouseOver={() => setHoveredTruck(tag)} onMouseOut={() => setHoveredTruck(null)} name="Loadings" stackId={tag} fill={tripTypeColor[2]} />
                                        <Bar dataKey={`yard_shifts-${tag}`} onMouseOver={() => setHoveredTruck(tag)} onMouseOut={() => setHoveredTruck(null)} name="Yard Shifts" stackId={tag} fill={tripTypeColor[3]} />
                                    </React.Fragment>
                                ))}


                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <ProTable
                    columns={columns}
                    dataSource={trucksTotal}
                    search={false}
                    pagination={false}
                />
            </div>
        </div>
    );
}
