import "./styles.css";
import { Typography, message, DatePicker, Button, Table, Checkbox } from 'antd';
import { useEffect, useState, PureComponent } from 'react';
import axios from 'axios';
import React from "react";
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { DateTime } = require("luxon");


import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line
} from "recharts";
import { capitalizeFirstLetter, tripTypeColor } from '../Helpers';

import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

export default function UnitNumber() {

    const [data, setData] = useState([]);
    const [totalCountData, setTotalCountData] = useState([]);
    const [fetchedStartDate, setFetchedStartDate] = useState("");
    const [fetchedEndDate, setFetchedEndDate] = useState("");
    const [verificationDate, setDate] = useState({});  // For date selection
    const [loading, setLoading] = useState(false);
    const [showMerged, toggleMergedData] = useState(false);

    const [yDomain, setYDomain] = useState("auto");

    // Different data sources for nested tables
    const [modifiedData, setModifiedData] = useState([]);
    const [verifiedData, setVerifiedData] = useState([]);

    const [startDate, setStartDate] = useState(null);

    const [lineChartData, setLineChartData] = useState([]);

    const getYearMonth = (date) => date.year() * 12 + date.month();

    useEffect(() => {

        // Fetch original trip statistics
        fetchDataRange({ showMerged: showMerged });
    }, []);

    useEffect(() => {
        fetchDataRange({ showMerged: showMerged })
    }, [showMerged])

    const fetchData = async (params = {}) => {
        await axios.get(window.vtrack_config.REACT_APP_API_URL + "/metrics/verifications_per_day", { params })
            .then(response => {
                if (response.status === 200) {
                    const responseData = response.data.data;
                    let tempTotalCount = [];
                    const tempTotal = response.data.total;
                    let verifiedChildren = [];

                    for (const [key, value] of Object.entries(tempTotal)) {
                        let percentage = 100;

                        if (key.toLowerCase() === "unverified" || key.toLowerCase() === "verified") {
                            if (tempTotal.trips > 0) percentage = (value * 100) / tempTotal.trips;
                        } else if (key.toLowerCase() !== "trips") {
                            const total = tempTotal.verified;
                            percentage = total > 0 ? (value * 100) / total : 0;
                        }

                        // Root of the table
                        if (["trips", "unverified"].includes(key)) {
                            tempTotalCount.push({
                                total: capitalizeFirstLetter(key),
                                key: key.toLowerCase(),
                                number: value,
                                percent: percentage.toFixed(2),
                            });
                        }

                        // nested tables inside 'verified' nested table
                        // 3rd depth
                        else if (key.toLowerCase() === "modified") {
                            // Nest the character difference categories under "modified"

                            verifiedChildren.push({
                                total: "Modified",
                                key: "modified",
                                number: value,
                                percent: percentage.toFixed(2)
                            });
                            setModifiedData([
                                {
                                    total: "1 Character Difference",
                                    number: tempTotal["1"],
                                    percent: (tempTotal["1"] * 100 / value).toFixed(2),
                                },
                                {
                                    total: "2 Characters Difference",
                                    number: tempTotal["2"],
                                    percent: (tempTotal["2"] * 100 / value).toFixed(2),
                                },
                                {
                                    total: ">2 Characters Difference",
                                    number: tempTotal[">2"],
                                    percent: (tempTotal[">2"] * 100 / value).toFixed(2),
                                },
                            ]);
                        }

                        else if (["invalid", "correct"].includes(key)) {
                            verifiedChildren.push({
                                total: capitalizeFirstLetter(key),
                                key: key.toLowerCase(),
                                number: value,
                                percent: percentage.toFixed(2),
                            });
                        }


                    }

                    // 2nd depth
                    tempTotalCount.push({
                        total: "Verified",
                        key: "verified",
                        number: tempTotal.verified,
                        percent: ((tempTotal.verified * 100) / tempTotal.trips).toFixed(2)
                    });
                    setVerifiedData(verifiedChildren);

                    setTotalCountData(tempTotalCount);
                    console.log(responseData);
                    setData(responseData);

                    setFetchedStartDate(responseData[0].trip_date);
                    setFetchedEndDate(responseData[responseData.length - 1].trip_date);
                } else {
                    console.log(response);
                    message.error("Could not load original trip statistics");
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load original trip statistics");
            });
    };

    function populateLineChart(apiData){
        let tempArray = [];
        for (let i = 0; i < apiData.length; i++) {
            const currentDay = apiData[i];
            tempArray.push({verified: currentDay.verified, correct: currentDay.correct, trip_date})
        }
    }

    function expandedRowRender(record) {

        let dataSource = [];
        if (record.key == "verified") {
            return (<Table columns={columns} dataSource={verifiedData}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    rowExpandable: (record) => record.key === "modified",
                    defaultExpandedRowKeys: ["modified"]
                }}
                size="small"
                pagination={false} />
            )
        }
        else if (record.key == "modified") {
            dataSource = modifiedData;
        }

        return (
            <Table columns={columns} dataSource={dataSource} pagination={false} />
        )
    }

    const fetchDataRange = async () => {
        if (!verificationDate) {
            message.error("Please select a date.");
            return;
        }
        let { start, end } = verificationDate
        start = start != null ? DateTime.fromISO(start).toFormat("yyyy-MM-dd") : null;
        end = end != null ? DateTime.fromISO(end).toFormat("yyyy-MM-dd") : null;

        const params = {
            start: start,
            end: end
        }

        setLoading(true);

        fetchData({ ...params, showMerged: showMerged });

        setLoading(false);
    };

    function handleDateChange(date) {
        let start = date[0] != null ? DateTime.fromHTTP(date[0].toString()) : null;
        let end = date[1] != null ? DateTime.fromHTTP(date[1].toString()) : null;

        start = start ? start.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        end = end ? end.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;

        setDate(
            {
                start,
                end
            }
        )
    }

    const onCalendarChange = (dates) => {
        if (dates && dates[0]) {
            setStartDate(DateTime.fromJSDate(dates[0].toDate()));
        } else {
            setStartDate(null);
        }
    };

    const disabledDate = (current, { from, type }) => {
        if (from) {
            const minDate = from.add(-29, 'days');
            const maxDate = from.add(29, 'days');
            switch (type) {
                case 'year':
                    return current.year() < minDate.year() || current.year() > maxDate.year();
                case 'month':
                    return (
                        getYearMonth(current) < getYearMonth(minDate) ||
                        getYearMonth(current) > getYearMonth(maxDate)
                    );
                default:
                    return Math.abs(current.diff(from, 'days')) >= 30;
            }
        }
        return false;
    };


    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            const date = new Date(payload.value);
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-45)"
                    >
                        {`${date.toLocaleDateString("en-UK", {
                            month: "short",
                            day: "numeric",
                        })} (${date.toLocaleDateString("en-UK", { weekday: "short" })})`}
                    </text>
                </g>
            );
        }
    }

    function CustomTooltip(props) {
        if (props.active && props.payload && props.payload.length) {
            //Add trips count that
            const newPayload = [
                ...props.payload,
                {
                    name: "Total",
                    value: props.payload[0].payload.trip_count,
                }
            ];


            //return <DefaultTooltipContent {...props} payload={newPayload} />
            return (
                <div className="custom-tooltip">
                    <span className="tooltip-label">{`Date: ${props.label}`}</span>
                    {newPayload.map((entry, index) => (
                        <div className="tooltip-entry" key={`tooltip-entry-${index}`} style={{ color: entry.fill }}>
                            <strong>{entry.name}:</strong> {entry.value}
                            {newPayload[index].payload ?
                                (entry.name.toLowerCase() == "unverified") ? (
                                    ` (${newPayload[index].payload.trip_count > 0
                                        ? ((entry.value / newPayload[index].payload.trip_count) * 100).toFixed(2)
                                        : 0}%)`
                                ) : (
                                    ` (${(newPayload[index].payload.correct + newPayload[index].payload.modified + newPayload[index].payload.invalid) > 0
                                        ? ((entry.value / (newPayload[index].payload.correct + newPayload[index].payload.modified + newPayload[index].payload.invalid) * 100).toFixed(2))
                                        : 0}%)`
                                )
                                : ""}
                            {entry.dataKey == "modified" && (
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', padding: '4px 0' }}>
                                    {Object.keys(newPayload[0].payload.levenshtein_distance).map((key, idx) => {
                                        if (key === "0") return null; // Skip rendering if key is '0'
                                        return (
                                            <React.Fragment key={`${index}-${key}-${idx}`}>
                                                <strong style={{ marginLeft: '16px', marginRight: '8px', textAlign: 'right' }} >{key} char:</strong>
                                                <span>{newPayload[0].payload.levenshtein_distance[key]}</span>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <DefaultTooltipContent {...props} />;
        }
    }

    const columns = [
        {
            title: "Total",
            key: "total",
            dataIndex: "total"
        },
        {
            title: "Amount",
            key: "number",
            dataIndex: "number"
        },
        {
            title: "Percentage %",
            key: "percent",
            dataIndex: "percent"
        }
    ]

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>

            {/* Date selection */}
            <div style={{ marginTop: 20 }}>
                <Text>Select Date:</Text>
                <RangePicker
                    allowEmpty={[false, false]}
                    style={{ marginLeft: 10 }}
                    onChange={(value) => { handleDateChange(value) }}
                    onCalendarChange={onCalendarChange}
                    disabledDate={disabledDate}
                />

            </div>

            {/* Fetch data button */}
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={fetchDataRange} loading={loading}>
                    Fetch Data
                </Button>
            </div>

            <Title level={2}>Trailer Id Verifications</Title>

            <Checkbox onChange={e => { toggleMergedData(e.target.checked) }} checked={showMerged}>Show merged data</Checkbox> <br />

            <Text strong>Range: </Text><span>{fetchedStartDate} - {fetchedEndDate}</span>
            <div style={{ height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={700}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 128,
                        }}
                        padding={{
                            bottom: 160,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="trip_date"
                            height={60}
                            tick={<CustomizedAxisTick />}
                            interval={0}
                        />
                        <YAxis domain={[0, yDomain]}
                            label={{
                                value: 'Number of Trips',
                                angle: -90, // Rotate the label for vertical orientation
                                position: 'insideLeft', // Position the label inside the Y-axis on the left
                                offset: -10, // Adjust offset to move it closer to the axis
                                style: { textAnchor: 'middle' } // Center-align the text
                            }}
                        />
                        <Tooltip labelFormatter={(label) => {
                            const date = new Date(label);
                            return date.toLocaleDateString("en-UK", {
                                weekday: "long",  // Full weekday name
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            });
                        }}
                            formatter={(value, name, props) => {

                                if (props.payload) {
                                    const totalTrips = props.payload.trip_count;  // Use the trip_count from payload
                                    const percentage = ((value / totalTrips) * 100).toFixed(2);  // Calculate percentage
                                    return [`${value} (${percentage}%)`, name];
                                } else {
                                    return [value, name];  // Return the formatted value with percentage
                                }
                            }}
                            content={<CustomTooltip />}
                        />
                        <Legend wrapperStyle={{ marginBottom: "-48px" }} />
                        <Bar dataKey="correct" name="Correct" stackId="a" fill="green" />
                        <Bar dataKey="modified" name="Modified" stackId="a" fill="blue" />
                        <Bar dataKey="invalid" name="Invalid" stackId="a" fill="orange" />
                        <Bar dataKey="unverified" name="Unverified" stackId="a" fill="red" />
                    </BarChart>

                    {/* <LineChart data={data} width={500} height={300}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="accuracy" stroke="#82ca9d" />
                    </LineChart> */}

                </ResponsiveContainer>
            </div>

            <Table
                columns={columns}
                dataSource={totalCountData}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    rowExpandable: (record) => record.key === "verified",
                    defaultExpandedRowKeys: ["verified"]
                }}
                size="small" />
        </div>
    )
}